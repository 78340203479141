import React, { Component } from "react";
import { Link } from "react-router-dom";
// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import countriesImg from "../../images/ioai/countries.png";
import shapingTheFutureImg from "../../images/ioai/shaping_the_future.png";
import aiImg from "../../images/ioai/ai.png";
import dallorImg from "../../images/ioai/dallor.png";
import balanceImg from "../../images/ioai/balance.png";
import futureImg from "../../images/ioai/future.png";
import soundImg from "../../images/ioai/sound.png";
import medalImg from "../../images/ioai/medal.png";

import logoAIML from "../../images/ioai/logo_aiml.png";
import logoSAChineseNews from "../../images/ioai/logo_sa_chinese_news.jpg";
import logoLC from "../../images/ioai/logo_lc.png";
import logoAAEGT from "../../images/ioai/logo_AAEGT.jpg";
import logoChess from "../../images/ioai/logo_chess.png";
import logoGTCASA from "../../images/ioai/logo_GTCASA.jpg";
import logoSA from "../../images/ioai/logo_sa.png";
import logoUoA from "../../images/ioai/logo_uoa.png";
import logoCityMedical from "../../images/ioai/logo_citymedical.png";

import { withTranslation } from "react-i18next";

import { Card, Col, Row } from 'antd';
import { Divider } from 'antd';

const images = [
    logoLC,
    logoAIML,
    logoSAChineseNews,
    logoAAEGT,
    logoChess,
    logoGTCASA,
    logoSA,
    logoUoA,
    logoCityMedical,
];

class IOAISponsor extends Component {
	constructor(props) {
		super(props);
		this.wrapperRef = React.createRef();
		this.state = {

		};
	}

	componentDidMount() {
		
	}

	render() {
		const { t } = this.props;

		return (
			<>
				<Header />
				<div className="page-content">
					<div className="content-block" id="content-area">
						<div className="page-banner ovbl-dark" style={{ backgroundImage: `url(${bannerImg})` }}>
							<div className="container">
								<div className="page-banner-entry">
									<h1 className="text-white">{t("IOAI_Sponsor_Title")}</h1>
								</div>
							</div>
						</div>
						<div className="breadcrumb-row">
							<div className="container">
								<ul className="list-inline">
									<li>
										<Link to="/">{t("IOAI_Sponsor_LinkHome")}</Link>
									</li>
									<li>{t("IOAI_Sponsor_LinkIOAI")}</li>
								</ul>
							</div>
						</div>
						{/* Sponsor Tier */}
						<div className="content-block m-tb50" id="content-area">
							<div className="container">
								<div className="section-area section-sp1">
									<div className="container">
										<div className="row">
											<div className="col-12 heading-bx style1 text-center">
												<h2 className="title-head">
													{t("IOAI_Sponsor_Tier_Title")}
												</h2>
											</div>
										</div>
									</div>
								</div>
								{/* Cards Container */}
								<div className="container mt-4" style={{ maxWidth: '90%', margin: '0 auto' }}>
									<h4 className="text-center">{t("IOAI_Sponsor_Platinum_Title")}</h4>
									<div className="row">
										{/* Platinum 1 */}
										<div className="col-12 col-md-6 mb-4 d-flex align-items-stretch">
											<div className="card" style={{ borderRadius: '15px', backgroundColor: '#eaeff6', margin: '0.5em' }}>
												<img className="card-img-top" alt="" src={logoLC}
													style={{ maxWidth: '100%', height: 'auto', textAlign: 'left', padding: '1em', backgroundColor: 'white' }} />
												<div className="card-body d-flex flex-column">
													<h5 className="card-title">{t("IOAI_Sponsor_Platinum_Title_1")}</h5>
													<p className="card-text flex-grow-1">
														{t("IOAI_Sponsor_Platinum_Content_1")}
													</p>
													<a href="https://www.leadingcoding.com.au/" className="btn btn-primary mt-auto">Learn More</a>
												</div>
											</div>
										</div>
										{/* Platinum 2 */}
										<div className="col-lg-6 col-md-12 col-sm-12 mb-4 d-flex align-items-stretch">
											<div className="card" style={{ borderRadius: '15px', backgroundColor: '#eaeff6', margin: '0.5em' }}>
												<img className="card-img-top" alt="" src={logoAIML} style={{ maxWidth: '100%', height: 'auto', textAlign: 'left', padding: '1em', backgroundColor: 'white' }} />
												<div className="card-body d-flex flex-column">
													<h5 className="card-title">{t("IOAI_Sponsor_Platinum_Title_2")}</h5>
													<p className="card-text flex-grow-1">
														{t("IOAI_Sponsor_Platinum_Content_2")}
													</p>
													<a href="https://www.adelaide.edu.au/aiml/" className="btn btn-primary mt-auto">Learn More</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<Divider />
								{/* Gold 1 */}
								<div className="container mt-4" style={{ maxWidth: '90%', margin: '0 auto' }}>
									<h4 className="text-center">{t("IOAI_Sponsor_Gold_Title")}</h4>
									<div className="row">
										<div className="col-lg-6 col-md-12 col-sm-12 mb-4 d-flex align-items-stretch">
											<div className="card" style={{ borderRadius: '15px', backgroundColor: '#eaeff6', margin: '0.5em' }}>
												<img className="card-img-top" alt="" src={logoSAChineseNews} style={{ maxWidth: '100%', height: 'auto', textAlign: 'left', padding: '1em', backgroundColor: 'white' }} />
												<div className="card-body d-flex flex-column">
													<h5 className="card-title">{t("IOAI_Sponsor_Gold_Title_1")}</h5>
													<p className="card-text flex-grow-1">
														{t("IOAI_Sponsor_Gold_Content_1")}
													</p>
													<a href="https://www.sacn.com.au/" className="btn btn-primary mt-auto">Learn More</a>
												</div>
											</div>
										</div>
										<div className="col-lg-6 col-md-12 col-sm-12 mb-4 d-flex align-items-stretch">
											<div className="card" style={{ borderRadius: '15px', backgroundColor: '#eaeff6', margin: '0.5em' }}>
												<img className="card-img-top img-fluid mx-auto" alt="" src={logoCityMedical} style={{ textAlign: 'center', padding: '1em', backgroundColor: 'white' }} />
												<div className="card-body d-flex flex-column">
													<h5 className="card-title">{t("IOAI_Sponsor_Gold_Title_2")}</h5>
													<p className="card-text flex-grow-1">
														{t("IOAI_Sponsor_Gold_Content_2")}
													</p>
													<a href="/" className="btn btn-primary mt-auto disabled">Learn More</a>
												</div>
											</div>
											
										</div>
									</div>
								</div>
								
								{/* end of Gold */}
							</div>
						</div>
						<Divider />
						{/* end of Sponsor Tier */}

						{/* sponsors and partners scroll */}
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row">
									<div className="col-12 heading-bx style1 text-center">
										<h2 className="title-head">
											{t("IOAI_Sponsor_Scroll_Title")}
										</h2>
									</div>
								</div>
							</div>
						</div>
						
						
						{/* partner scroll */}
						<div>
            {/* sponsors and partners scroll */}
            <div className="image-container">
                <div className="image-wrapper">
                    <div className="images">
                        {images.map((image, index) => (
                            <img className="image" key={index} src={image} alt={`Slide ${index + 1}`} />
                        ))}
                    </div>
                    <div className="images">
                        {images.map((image, index) => (
                            <img className="image" key={index} src={image} alt={`Slide ${index + 1}`} />
                        ))}
                    </div>
                </div>
            </div>
            {/* end of sponsors and partners */}
        </div>



						<Divider />
						{/* end of sponsors and partners */}

						<div className="section-area section-sp1">
							<div className="container">
								<div className="row">
									<div className="col-12 heading-bx style1 text-center">
										<h2 className="title-head">
											{t("IOAI_Sponsor_Title_Sub")}
										</h2>
									</div>
								</div>
							</div>
						</div>
						<Row justify="center" align="middle" style={{ marginRight: '80px', marginLeft: '80px', marginBottom: '48px' }}>
							<Col xs={24} sm={24} md={12} lg={8} align="left" style={{ marginRight: '10px', border: '0px solid #ddd', padding: '20px' }}>
								<div>
									<h3>
										{t("IOAI_Sponsor_Row1_Title")}
									</h3>
									<p>
										<a rel="noopener noreferrer" target="_blank" href="https://ioai-official.org/countries" style={{ textDecoration: "underline" }}>
											<b>
												{t("IOAI_Sponsor_Row1_Column1_Content1")}
											</b>
										</a>
										{t("IOAI_Sponsor_Row1_Column1_Content2")}
									</p>
									<p>
										{t("IOAI_Sponsor_Row1_Column1_Content3")}
									</p>
								</div>
							</Col>
							<Col xs={24} sm={24} md={12} lg={10} align="middle" style={{ marginLeft: '10px', border: '0px solid #ddd', padding: '20px' }}>
								<div style={{ maxHeight: '300px', overflow: 'auto' }}>
									<img alt="" src={countriesImg} style={{ maxWidth: '80%', height: 'auto', maxHeight: '260px' }} />
								</div>
							</Col>
						</Row>
						<Row justify="center" align="middle" style={{ marginRight: '80px', marginLeft: '80px', marginBottom: '48px' }}>
							<Col xs={24} sm={24} md={12} lg={10} align="middle" style={{ marginLeft: '10px', border: '0px solid #ddd', padding: '20px' }}>
								<div style={{ maxHeight: '300px', overflow: 'auto' }}>
									<img alt="" src={shapingTheFutureImg} style={{ maxWidth: '80%', height: 'auto', maxHeight: '260px' }} />
								</div>
							</Col>
							<Col xs={24} sm={24} md={12} lg={8} align="left" style={{ marginRight: '10px', border: '0px solid #ddd', padding: '20px' }}>
								<div>
									<h3>{t("IOAI_Sponsor_Row2_Title")}</h3>
									<p>{t("IOAI_Sponsor_Row2_Column2_Content1")}</p>
									<p>{t("IOAI_Sponsor_Row2_Column2_Content2")}</p>
								</div>
							</Col>
						</Row>
						<Row justify="center" align="middle" style={{ marginRight: '80px', marginLeft: '80px', marginBottom: '48px' }}>
							<Col xs={24} sm={24} md={12} lg={8} align="left" style={{ marginRight: '10px', border: '0px solid #ddd', padding: '20px' }}>
								<div>
									<h3>{t("IOAI_Sponsor_Row3_Title")}</h3>
									<ul style={{ paddingLeft: 20, fontSize: 16, lineHeight: '1.6' }}>
										<li style={{ marginBottom: 12 }}>
											<strong>{t("IOAI_Sponsor_Row3_Column1_Title1")}</strong>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content1")}</p>
										</li>
										<li style={{ marginBottom: 12 }}>
											<strong>{t("IOAI_Sponsor_Row3_Column1_Title2")}</strong>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content2_1")}</p>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content2_2")}</p>
										</li>
										<li style={{ marginBottom: 12 }}>
											<strong>{t("IOAI_Sponsor_Row3_Column1_Title3")}</strong>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content3_1")}</p>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content3_2")}</p>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content3_3")}</p>
										</li>
										<li style={{ marginBottom: 12 }}>
											<strong>{t("IOAI_Sponsor_Row3_Column1_Title4")}</strong>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content4")}</p>
										</li>
										<li style={{ marginBottom: 12 }}>
											<strong>{t("IOAI_Sponsor_Row3_Column1_Title5")}</strong>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content5_1")}</p>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content5_2")}</p>
										</li>
										<li style={{ marginBottom: 12 }}>
											<strong>{t("IOAI_Sponsor_Row3_Column1_Title6")}</strong>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content6_1")}</p>
											<p style={{ color: "#4682B4", fontStyle: 'italic', textDecoration: "none", display: "block", marginBottom: 8 }}>{t("IOAI_Sponsor_Row3_Column1_Content6_2")}</p>
										</li>
									</ul>
								</div>
							</Col>

							<Col xs={24} sm={24} md={12} lg={10} align="middle" style={{ marginLeft: '10px', border: '0px solid #ddd', padding: '20px' }}>
								<div style={{ maxHeight: '300px', overflow: 'auto' }}>
									<img alt="" src={aiImg} style={{ maxWidth: '80%', height: 'auto', maxHeight: '260px' }} />
								</div>
							</Col>
						</Row>
						<Divider />
						<div>
							<div className="section-area section-sp1">
								<div className="container-fluid">
									<div className="row" style={{ marginBottom: -60, marginTop: -40 }}>
										<div className="col-md-12 heading-bx style1 text-center">
											<h2 className="title-head">{t("IOAI_Sponsor_Row4_Title")}</h2>
										</div>
									</div>
								</div>
							</div>
						</div>

						<Row justify="center" align="center">
							<Col xs={24} sm={24} md={8} lg={6} align="center" style={{ marginBottom: '20px' }}>
								<Card hoverable style={{ width: 300 }}>
									<Row gutter={[0, 8]} align="center">
										<div style={{ width: '100%', height: 'auto' }}>
											<Col span={4}>
												<img alt="" src={dallorImg} />
											</Col>
										</div>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={30}>
											<h6>{t("IOAI_Sponsor_Card_2_Title")}</h6>
										</Col>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={50}>
											<p style={{
												color: "#4682B4",
												fontStyle: 'italic',
												marginTop: 8,
											}}>{t("IOAI_Sponsor_Card_2_Content")}</p>
										</Col>
									</Row>
								</Card>
							</Col>

							<Col xs={24} sm={24} md={8} lg={6} align="center" style={{ marginBottom: '20px' }}>
								<Card hoverable style={{ width: 300 }}>
									<Row gutter={[0, 8]} align="center">
										<div style={{ width: '100%', height: 'auto' }}>
											<Col span={4}>
												<img alt="" src={balanceImg} />
											</Col>
										</div>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={30}>
											<h6>{t("IOAI_Sponsor_Card_3_Title")}</h6>
										</Col>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={50}>
											<p style={{
												color: "#4682B4",
												fontStyle: 'italic',
												marginTop: 8,
											}}>{t("IOAI_Sponsor_Card_3_Content")}</p>
										</Col>
									</Row>
								</Card>
							</Col>

							<Col xs={24} sm={24} md={8} lg={6} align="center" style={{ marginBottom: '20px' }}>
								<Card hoverable style={{ width: 300 }}>
									<Row gutter={[0, 8]} align="center">
										<div style={{ width: '100%', height: 'auto' }}>
											<Col span={4}>
												<img alt="" src={futureImg} />
											</Col>
										</div>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={30}>
											<h6>{t("IOAI_Sponsor_Card_4_Title")}</h6>
										</Col>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={50}>
											<p style={{
												color: "#4682B4",
												fontStyle: 'italic',
												marginTop: 8,
											}}>{t("IOAI_Sponsor_Card_4_Content")}</p>
										</Col>
									</Row>
								</Card>
							</Col>

							<Col xs={24} sm={24} md={8} lg={6} align="center" style={{ marginBottom: '20px' }}>
								<Card hoverable style={{ width: 300 }}>
									<Row gutter={[0, 8]} align="center">
										<div style={{ width: '100%', height: 'auto' }}>
											<Col span={4}>
												<img alt="" src={soundImg} />
											</Col>
										</div>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={30}>
											<h6>{t("IOAI_Sponsor_Card_5_Title")}</h6>
										</Col>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={50}>
											<p style={{
												color: "#4682B4",
												fontStyle: 'italic',
												marginTop: 8,
											}}>{t("IOAI_Sponsor_Card_5_Content")}</p>
										</Col>
									</Row>
								</Card>
							</Col>

							<Col xs={24} sm={24} md={8} lg={6} align="center" style={{ marginBottom: '20px' }}>
								<Card hoverable style={{ width: 300 }}>
									<Row gutter={[0, 8]} align="center">
										<div style={{ width: '100%', height: 'auto' }}>
											<Col span={4}>
												<img alt="" src={medalImg} />
											</Col>
										</div>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={30}>
											<h6>{t("IOAI_Sponsor_Card_1_Title")}</h6>
										</Col>
									</Row>
									<Row gutter={[0, 8]} align="center">
										<Col span={50}>
											<p style={{
												color: "#4682B4",
												fontStyle: 'italic',
												marginTop: 8,
											}}>{t("IOAI_Sponsor_Card_1_Content")}</p>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>

						<Divider />
						<div className="section-area section-sp1">
							<div className="container-fluid">
								<div className="row justify-content-center"> {/* 将内容水平居中 */}
									<div className="col-md-8 heading-bx style1 text-center"> {/* 设置列大小为8 */}
										<h2 className="title-head">{t("IOAI_Sponsor_Title_Sub_2")}</h2>
									</div>
									<div className="col-md-8 text-center"> {/* 设置列大小为8 */}
										<p>{t("IOAI_Sponsor_Content_Sub_2")}</p>
										<p style={{
											color: "#4682B4",
											fontStyle: 'italic',
											marginTop: 8,
										}}>{t("IOAI_Sponsor_Content_Sub_3")}
											<span><a rel="noopener" href="mailto:admin@louiscelinetech.com.au" style={{ textDecoration: "underline" }}>{t("IOAI_Sponsor_Content_Sub_4")}</a></span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</>
		);
	}
}

export default IOAISponsor = withTranslation()(IOAISponsor);
