import React from 'react';

import logoLC from "../../images/ioai/logo_lc.png";
import logoAIML from "../../images/ioai/logo_aiml.png";
import logoSAChineseNews from "../../images/ioai/logo_sa_chinese_news.jpg";
import logoAAEGT from "../../images/ioai/logo_AAEGT.jpg";
import logoChess from "../../images/ioai/logo_chess.png";
import logoGTCASA from "../../images/ioai/logo_GTCASA.jpg";
import logoSA from "../../images/ioai/logo_sa.png";
import logoUoA from "../../images/ioai/logo_uoa.png";
import logoCityMedical from "../../images/ioai/logo_citymedical.png";
import logoSkinTech from "../../images/logo/skintech.jpg";
import logoHerTechPath from "../../images/logo/hertechpath.png";

const images = [
    logoLC,
    logoAIML,
    logoSAChineseNews,
    logoAAEGT,
    logoChess,
    logoGTCASA,
    logoSA,
    logoUoA,
    logoCityMedical,
    logoSkinTech,
    logoHerTechPath,
];

const PartnerScroll = () => {
    return (
        <div>
            {/* sponsors and partners scroll */}
            <div className="image-container">
                <div className="image-wrapper">
                    <div className="images">
                        {images.map((image, index) => (
                            <img className="image" key={index} src={image} alt={`Slide ${index + 1}`} />
                        ))}
                    </div>
                    <div className="images">
                        {images.map((image, index) => (
                            <img className="image" key={index} src={image} alt={`Slide ${index + 1}`} />
                        ))}
                    </div>
                </div>
            </div>
            {/* end of sponsors and partners */}
        </div>
    );
};

export default PartnerScroll;