import React, { Component } from "react";

// Layout
import Header1 from "../layout/header/header1";

// Elements
import Slider2 from "../elements/slider/slider2";
import ServicesContent3 from "../elements/services-content-3";
import Testimonial3 from "../elements/testimonial2";
import PopularCoursesSlider2 from "../elements/popular-courses-slider2";

import { withTranslation } from "react-i18next";

// Images
import bg4 from "../../images/background/bg4.jpg";
import PartnerScroll from "../elements/partner-scroll";

	
class Index3 extends Component {
	componentDidMount() {
		
	}
	render() {
		const { t } = this.props;
		return (
			<>
				<Header1 />

				<div className="page-content bg-white">
					<Slider2 />

					<div className="content-block" id="content-area">
						<div
							className="popular-courses-bx"
							style={{
								backgroundImage: "url(" + bg4 + ")",
								backgroundSize: "cover",
							}}
						>
							<div className="section-area section-sp3">
								<div className="row">
									<div className="col-md-12 heading-bx style1 text-center">
										<h2 className="title-head">{t("Home_Pop_course_H2")}</h2>
									</div>
								</div>

								<ServicesContent3 />
							</div>

							<div className="section-area section-sp1">
								<div className="container-fluid">
									<div className="row">
										<div className="col-md-12 heading-bx style1 text-center">
											<h2 className="title-head">{t("Home_Section_H2")}</h2>
											<p>{t("Home_Sectione_P")}</p>
										</div>
									</div>

									<PopularCoursesSlider2 />
								</div>
							</div>
						</div>

						<Testimonial3 />
						
						<div className="section-area section-sp3">
									<div className="col-md-12 heading-bx style1 text-center">
										<h2 className="title-head">{t("Home_Partner_Scroll_H2")}</h2>
									</div>

								<PartnerScroll />
							</div>

					</div>
				</div>
			</>
		);
	}
}

export default Index3 = withTranslation()(Index3);
