import React,{Component} from 'react';
import Slider from "react-slick";

// Images
import bg7 from '../../images/background/bg7.jpg';

import { withTranslation } from 'react-i18next';



// Content
const content = [
	{
		name: "Testimonial2_Name1",
		work: "Testimonial2_work1",
		text: "Testimonial2_text1",
	},
	{
		name: "Testimonial2_Name2",
		work: "Testimonial2_work2",
		text: "Testimonial2_text2",
	},
	{
		name: "Testimonial2_Name3",
		work: "Testimonial2_work3",
		text: "Testimonial2_text3",
	},
	{
		name: "Testimonial2_Name4",
		work: "Testimonial2_work4",
		text: "Testimonial2_text4",
	},
	{
		name: "Testimonial2_Name5",
		work: "Testimonial2_work5",
		text: "Testimonial2_text5",
	},
	{
		name: "Testimonial2_Name6",
		work: "Testimonial2_work6",
		text: "Testimonial2_text6",
	},
	{
		name: "Testimonial2_Name7",
		work: "Testimonial2_work7",
		text: "Testimonial2_text7",
	},
	{
		name: "Testimonial2_Name8",
		work: "Testimonial2_work8",
		text: "Testimonial2_text8",
	},
	{
		name: "Testimonial2_Name9",
		work: "Testimonial2_work9",
		text: "Testimonial2_text9",
	},
	{
		name: "Testimonial2_Name10",
		work: "Testimonial2_work10",
		text: "Testimonial2_text10",
	},
	{
		name: "Testimonial2_Name11",
		work: "Testimonial2_work11",
		text: "Testimonial2_text11",
	},
]

class Testimonial2 extends Component{
	componentDidMount() {
		
	}
	render()
	{
		const { t } = this.props;
		
		const settings = {
			infinite: true,
			dots: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				<div className="section-area section-sp2" style={{backgroundImage:"url("+bg7+")", backgroundSize:"cover", backgroundPosition: "center"}}>
					<div className="container">
						<div className="row">
							<div className="col-md-12 heading-bx style1 text-center">
								<h2 className="title-head">{ t('Testimonial2_Title') }</h2>
								<p>{ t('Testimonial2_P') }</p>
							</div>
						</div>
						<Slider {...settings} className="testimonial-carousel-2 slick-slider owl-btn-1 owl-none">
							{content.map((item, index)=>(
								<div className="slider-item" key={index}>
									<div className="testimonial-bx style1">
										<div className="testimonial-head">
											<div className="testimonial-info">
												<h5 className="name">{t(item.name)}</h5>
												<p>-{t(item.work)}</p>
											</div>
										</div>
										<div className="testimonial-content">
											<p>{t(item.text)}</p>
										</div>
									</div>
								</div>
							))}
						</Slider>
					</div>
				</div>
			</>
		);
	}
}

export default Testimonial2 =  withTranslation()(Testimonial2);