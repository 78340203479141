import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import news101Img from "../../images/news/news10_1.png";
import news102Img from "../../images/news/news10_2.jpg";
import news103Img from "../../images/news/news10_3.jpg";
import news104Img from "../../images/news/news10_4.jpg";
import news105Img from "../../images/news/news10_5.jpg";
import news106Img from "../../images/news/news10_6.jpg";
import news107Img from "../../images/news/news10_7.jpg";
import news108Img from "../../images/news/news10_8.jpg";
import news109Img from "../../images/news/news10_9.jpg";
import news1010Img from "../../images/news/news10_10.jpg";
import news1011Img from "../../images/news/news10_11.png";
import news1012Img from "../../images/news/news10_12.png";

import { withTranslation } from "react-i18next";


class news10 extends Component {
	componentDidMount() {

	}

	componentWillMount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("News10_Title");
	}

	componentWillUnmount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("Home_Title");
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("News10_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("News10_LinkHome")}</Link>
								</li>
								<li>{t("News10_LinkCourses")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("News10_Content_H")}</h1>
											<p>{t("News10_Content_P1")}</p>
											<img alt="" src={news101Img} />
											<hr />
											<p>{t("News10_Content_P2")}</p>
											<img src={news102Img} alt="" />
											<hr />
											<p>{t("News10_Content_P3")}</p>
											<img src={news103Img} alt="" />
											<hr />
											<p>{t("News10_Content_P4")}</p>
											<img src={news104Img} alt="" />
											<img src={news105Img} alt="" />
											<img src={news106Img} alt="" />
											<img src={news107Img} alt="" />
											<p>{t("News10_Content_P4_1")}</p>
											<img src={news108Img} alt="" />
											<hr />
											<p>{t("News10_Content_P5")}</p>
											<hr />
											<p>{t("News10_Content_P6")}</p>
											<img src={news109Img} alt="" />
											<hr />
											<p>{t("News10_Content_P7")}</p>
											<img src={news1010Img} alt="" />
											<img src={news1011Img} alt="" />
											<hr />
											<img src={news1012Img} alt="" />
											<p>{t("News10_Content_P8")}</p>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default news10 = withTranslation()(news10);
