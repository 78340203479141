import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import coursesPic1 from '../../images/courses/pic10.jpg';
import coursesPic2 from '../../images/courses/pic11.jpg';
import coursesPic3 from '../../images/courses/pic12.jpg';
import coursesPic4 from '../../images/courses/pic13.jpg';

import { withTranslation } from 'react-i18next';



// Content
const content = [
	{
		thumb: coursesPic2,
		title: "PopularCoursesSlider2_TitlePre",
		tag: "PopularCoursesSlider2_TagPre",
	},
	{
		thumb: coursesPic3,
		title: "PopularCoursesSlider2_TitleL1",
		tag: "PopularCoursesSlider2_TagL1",
	},
	{
		thumb: coursesPic4,
		title: "PopularCoursesSlider2_TitleL2",
		tag: "PopularCoursesSlider2_TagL2",
	},
	{
		thumb: coursesPic1,
		title: "PopularCoursesSlider2_TitleL3",
		tag: "PopularCoursesSlider2_TagL3",
	},
	{
		thumb: coursesPic2,
		title: "PopularCoursesSlider2_TitleL4",
		tag: "PopularCoursesSlider2_TagL4",
	},
	{
		thumb: coursesPic3,
		title: "PopularCoursesSlider2_TitleL5",
		tag: "PopularCoursesSlider2_TagL5",
	},
	{
		thumb: coursesPic4,
		title: "PopularCoursesSlider2_TitleL6",
		tag: "PopularCoursesSlider2_TagL6",
	},
]

class PopularCoursesSlider2 extends Component {
	componentDidMount() {

	}
	render() {
		const { t } = this.props;

		const settings = {
			infinite: true,
			dots: true,
			speed: 500,
			slidesToShow: 6,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 1500,
			responsive: [
				{
					breakpoint: 1500,
					settings: {
						slidesToShow: 6,
					}
				},
				{
					breakpoint: 1460,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 1300,
					settings: {
						slidesToShow: 4,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};

		return (
			<>
				<Slider {...settings} className="courses-carousel-2 slick-slider owl-none">
					{content.map((item, index) => (
						<div className="slider-item" key={index}>
							<div className="cours-bx">
								<div className="action-box">
									<img alt="" src={item.thumb} />
									<div className="info-bx">
										<span>{t(item.tag)}</span>
										{item.title === "PopularCoursesSlider2_TitleL6" ? (
											<Link to="/news-7" className="btn">{t("PopularCoursesSlider2_ReadMore")}</Link>
										) : (
											<Link to="/courses-details" className="btn">{t("PopularCoursesSlider2_ReadMore")}</Link>
										)}
									</div>
								</div>
								<div className="info-bx">
									<span>{t(item.tag)}</span>
									{item.title === "PopularCoursesSlider2_TitleL6" ? (
										<h6><Link to="/news-7">{t(item.title)}</Link></h6>
									) : (
										<h6><Link to="/courses-details">{t(item.title)}</Link></h6>
									)}
								</div>
							</div>
						</div>
					))}
				</Slider>
			</>
		);
	}
}

export default PopularCoursesSlider2 = withTranslation()(PopularCoursesSlider2);