import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import news51Img from "../../images/news/news5_1.png";
import news52Img from "../../images/news/news5_2.png";
import news53Img from "../../images/news/news5_3.png";
import news54Img from "../../images/news/news5_4.png";
import news55Img from "../../images/news/news5_5.png";
import news56Img from "../../images/news/news5_6.png";
import news57Img from "../../images/news/news5_7.png";

import { withTranslation } from "react-i18next";

	
class News5 extends Component {
	componentDidMount() {
		
	}

	componentWillMount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("News5_Title");
	}

	componentWillUnmount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("Home_Title");
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("News5_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("News5_LinkHome")}</Link>
								</li>
								<li>{t("News5_LinkCourses")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("News5_Content_H")}</h1>
											{/* <p>{t("News3_Content_Start")}<u><a rel="noopener noreferrer" href="https://mp.weixin.qq.com/s/IyF5uTa2fHDD7idQN-rDgg" target="_blank">{t("News3_Content_Link")}</a></u>{t("News3_Content_End")}</p> */}
											<p>{t("News5_Content_P1")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news51Img} /></p>
											<p>{t("News5_Content_P2")}</p>
											<p>{t("News5_Content_P3")}</p>
											<p>{t("News5_Content_P4")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news52Img} /></p>
											<p>{t("News5_Content_P5")}</p>
											<hr />
											<h5>{t("News5_Content_P6")}</h5>
											<p>{t("News5_Content_P7")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news53Img} /></p>
											<hr />
											<h5>{t("News5_Content_P9")}</h5>
											<strong>{t("News5_Content_P10")}</strong>
											<p>{t("News5_Content_P11")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news54Img} /></p>
											<strong>{t("News5_Content_P12")}</strong>
											<p>{t("News5_Content_P13")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news55Img} /></p>
											<strong>{t("News5_Content_P14")}</strong>
											<p>{t("News5_Content_P15")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news56Img} /></p>
											<strong>{t("News5_Content_P16")}</strong>
											<p>{t("News5_Content_P17")}</p>
											<p style={{textAlign: "center"}}><img alt="" src={news57Img} /></p>
											<hr />
											<p>{t("News5_Content_P18")}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default News5 = withTranslation()(News5);
