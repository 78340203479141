import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner3.jpg";
import news61Img from "../../images/news/news6_1.jpg";
import news62Img from "../../images/news/news6_2.jpg";
import news63Img from "../../images/news/news6_3.jpg";
import news64Img from "../../images/news/news6_4.jpg";
import news65Img from "../../images/news/news6_5.jpg";
import news66Img from "../../images/news/news6_6.jpg";
import news67Img from "../../images/news/news6_7.jpg";
import news68Img from "../../images/news/news6_8.jpg";
import news69Img from "../../images/news/news6_9.jpg";
import news610Img from "../../images/news/news6_10.jpg";
import news611Img from "../../images/news/news6_11.jpg";
import news612Img from "../../images/news/news6_12.jpg";
import news613Img from "../../images/news/news6_13.jpg";
import news614Img from "../../images/news/news6_14.jpg";
import news615Img from "../../images/news/news6_15.jpg";
import news616Img from "../../images/news/news6_16.jpg";
import news617Img from "../../images/news/news6_17.jpg";
import news618Img from "../../images/news/news6_18.jpg";
import news619Img from "../../images/news/news6_19.jpg";

import { withTranslation } from "react-i18next";


class News6 extends Component {
	componentDidMount() {

	}

	componentWillMount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("News6_Title");
	}

	componentWillUnmount() {
		const { t } = this.props;
		document.getElementById('title').innerText = t("Home_Title");
	}

	render() {
		const { t } = this.props;
		return (
			<>
				<Header />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("News6_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("News6_LinkHome")}</Link>
								</li>
								<li>{t("News6_LinkCourses")}</li>
							</ul>
						</div>
					</div>
					<div className="content-block">
						<div className="section-area section-sp1">
							<div className="container">
								<div className="row d-flex flex-row-reverse">
									<div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
										<div className="courses-post">
											<h1>{t("News6_Content_H")}</h1>
											<strong>{t("News6_Content_P1")}</strong>
											<p style={{ textAlign: "center" }}><img alt="" src={news61Img} /></p>
											<p>{t("News6_Content_P2")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news62Img} /></p>
											<p>{t("News6_Content_P3")}</p>											
											<p style={{ textAlign: "center" }}><img alt="" src={news63Img} /></p>
											<strong>{t("News6_Content_P4")}</strong>
											<p>{t("News6_Content_P5")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news64Img} /></p>
											<p>{t("News6_Content_P6")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news65Img} /></p>
											<strong>{t("News6_Content_P7")}</strong>
											<p>{t("News6_Content_P8")}</p>
											<p>{t("News6_Content_P9")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news66Img} /></p>
											<p>{t("News6_Content_P10")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news67Img} /></p>
											<p>{t("News6_Content_P11")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news68Img} /></p>
											<p>{t("News6_Content_P12")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news69Img} /></p>
											<p>{t("News6_Content_P13")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news610Img} /></p>
											<strong>{t("News6_Content_P14")}</strong>
											<p>{t("News6_Content_P15")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news611Img} /></p>
											<strong>{t("News6_Content_P16")}</strong>
											<p>{t("News6_Content_P17")}</p>
											<p>{t("News6_Content_P18")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news612Img} /></p>
											<strong>{t("News6_Content_P19")}</strong>
											<p>{t("News6_Content_P20")}</p>
											<p>{t("News6_Content_P21")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news613Img} /></p>
											<strong>{t("News6_Content_P22")}</strong>
											<p>{t("News6_Content_P23")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news614Img} /></p>
											<strong>{t("News6_Content_P24")}</strong>
											<p>{t("News6_Content_P25")}</p>
											<p>{t("News6_Content_P26")}</p>
											<p>{t("News6_Content_P27")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news615Img} /></p>
											<p>{t("News6_Content_P28")}</p>
											<p>{t("News6_Content_P29")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news616Img} /></p>
											<p>{t("News6_Content_P30")}</p>
											<p>{t("News6_Content_P31")}</p>
											<p>{t("News6_Content_P32")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news617Img} /></p>
											<p>{t("News6_Content_P33")}</p>
											<p>{t("News6_Content_P34")}</p>
											<p style={{ textAlign: "center" }}><img alt="" src={news618Img} /></p>
											<p>{t("News6_Content_P35")}</p>
											<p>{t("News6_Content_P36")}</p>											
											<p style={{ textAlign: "center" }}><img alt="" src={news619Img} /></p>

										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default News6 = withTranslation()(News6);
