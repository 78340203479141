import React, { Component } from "react";
import { Link } from "react-router-dom";

// Layout
import Header1 from "../layout/header/header1";

// Images
import bannerImg from "../../images/banner/banner1.jpg";
import weChatImg from "../../images/about/wechat.jpg";

import { withTranslation } from "react-i18next";

import axios from "axios";

import { toast } from "react-hot-toast";

import i18next from "i18next";


const content1 = [
	{
		Title: "Contact2_Title_1",
		Text: "Contact2_Text_1",
	},
	{
		Title: "Contact2_Title_2",
		Text: "Contact2_Text_2",
	},
	{
		Title: "Contact2_Title_3",
		Text: "Contact2_Text_3",
	},
	{
		Title: "Contact2_Title_4",
		Text: "Contact2_Text_4",
	},
	{
		Title: "Contact2_Title_5",
		Text: "Contact2_Text_5",
	},
	{
		Title: "Contact2_Title_6",
		Text: "Contact2_Text_6",
	},
	{
		Title: "Contact2_Title_7",
		Text: "Contact2_Text_7",
	},
];

class Contact2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			email: "",
			contact_number: "",
			gender: "",
			age: "",
			school: "",
			content: "",
			activeItem: "",
		};
		this.itemRefs = [];
	}
	componentDidMount() {

	}
	toggleItem = (id) => {
		this.setState((prevState) => ({
			activeItem: prevState.activeItem === id ? "" : id,
		}), () => {
			if (this.state.activeItem === id && this.itemRefs[id]) {
				this.itemRefs[id].scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		});
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		try {
			axios.post(
				"https://leadingcoding.com.au/contact-api/public/api/v1/send_email",
				this.state,
				{
					headers: {
						"x-api-key": "Uc.T*>n_#.1}5)r8mlU!Q$SEKson+[",
					},
				}
			);
			await axios.post(
				"https://leadingcoding.com.au/contact-api/public/api/v1/contact",
				this.state,
				{
					headers: {
						"x-api-key": "Uc.T*>n_#.1}5)r8mlU!Q$SEKson+[",
					},
				}
			);
			this.setState({
				name: "",
				email: "",
				contact_number: "",
				gender: "",
				age: "",
				school: "",
				content: "",
			});
			toast.success(i18next.t("Submit_Succeed_Tip"), {
				position: "bottom-center",
				duration: 5000,
			});
		} catch (error) {
			toast.error(i18next.t("Submit_Fail_Tip"), {
				position: "bottom-center",
				duration: 2000,
			});
		}
	};

	handleChange = (event) => {
		const { name, value } = event.target;
		this.setState({ [name]: value });
	};

	render() {
		const { t } = this.props;
		const { activeItem } = this.state;
		return (
			<>
				<Header1 />

				<div className="page-content">
					<div
						className="page-banner ovbl-dark"
						style={{ backgroundImage: "url(" + bannerImg + ")" }}
					>
						<div className="container">
							<div className="page-banner-entry">
								<h1 className="text-white">{t("Contact_Title")}</h1>
							</div>
						</div>
					</div>
					<div className="breadcrumb-row">
						<div className="container">
							<ul className="list-inline">
								<li>
									<Link to="/">{t("Contact_LinkHome")}</Link>
								</li>
								<li>{t("Contact_LinkContact")}</li>
							</ul>
						</div>
					</div>


					<div className="content-block">
						<div className="page-banner contact-page">
							<div className="container-fuild">
								{/* Accordion Section */}
								<div className="row m-lr0 p-t50">
									<div className="col-lg-10 col-md-10 p-lr30">
										<div className="container mt-3">
										<div className="heading-bx left p-r15">
											<h2 className="title-head">{t("Contact2_Title3")}</h2>
										</div>
											<div id="accordion">
												{content1.map((item, index) => (
													<div className="card" key={index} ref={(ref) => this.itemRefs[index] = ref}>
														<div className={`card-header ${activeItem === index ? 'collapsed' : ''}`} onClick={() => this.toggleItem(index)}>
															<h5 className="mb-0">
																<button
																	className={`button-link ${activeItem === index ? 'collapsed' : ''}`}
																	aria-expanded={activeItem === index}
																	aria-controls={`collapse${index}`}
																	style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
																>
																	<p>{t(item.Title)}</p>
																	<i className="bi bi-arrow-down-circle"></i>
																</button>
															</h5>
														</div>
														<div
															id={`collapse${index}`}
															className={`collapse ${activeItem === index ? 'show' : ''}`}
															aria-labelledby={`heading${index}`}
															data-parent="#accordion"
														>
															<div className="card-body">
																<p style={{ whiteSpace: 'pre-line' }}>{t(item.Text)}</p>
															</div>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
								{/* End Accordion Section */}

								<div className="row m-lr0">
									<div className="col-lg-6 col-md-6 section-sp2 p-lr30">

										<div className="heading-bx left p-r15">
											<h2 className="title-head">{t("Contact_Information")}</h2>
										</div>
										<div className="row placeani">
											<div className="col-lg-3 pull-right">
												<div className="label">
													<h3>{t("Contact_Phone_Label")}</h3>
												</div>
											</div>
											<div className="col-lg-6 pull-right">
												<div className="value">
													<div style={{ fontSize: 28 + "px" }}>
														{t("Contact_Phone_Value")}
													</div>
												</div>
											</div>
										</div>
										<div className="row placeani">
											<div className="col-lg-3 pull-right">
												<div className="label">
													<h3>{t("Contact_Email_Label")}</h3>
												</div>
											</div>
											<div className="col-lg-6 pull-right">
												<div className="value">
													<div style={{ fontSize: 28 + "px" }}>
														{t("Contact_Email_Value")}
													</div>
												</div>
											</div>
										</div>
										<div className="row placeani">
											<div className="col-lg-3 pull-right">
												<div className="label">
													<h3>{t("Contact_Wechat_Label")}</h3>
												</div>
											</div>
											<div className="col-lg-6 pull-right">
												<div className="value">
													<img alt=""
														style={{ width: 100 + "px", height: 100 + "px" }}
														src={weChatImg}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 section-sp2 p-lr30">
										<form
											className="contact-bx ajax-form"
											onSubmit={this.handleSubmit}
										>
											<div className="heading-bx left p-r15">
												<h2 className="title-head">{t("Contact2_Title1")}</h2>
											</div>
											<div className="row placeani">
												<div className="col-lg-6 ">
													<div className="form-group">
														<div className="input-group">
															<input
																name="name"
																value={this.state.name}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder1")}
																required
																className="form-control valid-character"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="email"
																value={this.state.email}
																onChange={this.handleChange}
																type="email"
																placeholder={t("Contact2_Placeholder2")}
																className="form-control"
																required
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="contact_number"
																value={this.state.contact_number}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder3")}
																required
																className="form-control int-value"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="gender"
																value={this.state.gender}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder4")}
																required
																className="form-control"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="age"
																value={this.state.age}
																onChange={this.handleChange}
																type="number"
																placeholder={t("Contact2_Placeholder6")}
																required
																className="form-control"
																min="0"
																max="100"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="form-group">
														<div className="input-group">
															<input
																name="school"
																value={this.state.school}
																onChange={this.handleChange}
																type="text"
																placeholder={t("Contact2_Placeholder7")}
																required
																className="form-control"
															/>
														</div>
													</div>
												</div>
												<div className="col-lg-12">
													<div className="form-group">
														<div className="input-group">
															<textarea
																name="content"
																value={this.state.content}
																onChange={this.handleChange}
																rows="4"
																placeholder={t("Contact2_Placeholder5")}
																className="form-control"
																required
															></textarea>
														</div>
													</div>
												</div>
												<div className="col-lg-12">
													<button
														name="submit"
														type="submit"
														value="Submit"
														className="btn button-md"
													>
														{" "}
														{t("Contact2_SendM")}
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</>
		);
	}
}

export default withTranslation()(Contact2);
